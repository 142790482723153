
.title-text {
    color: #16113F;
    font-size: 16px;
}
.person-info {
    display: flex;
    align-items: center;
    .avatar-box {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        background: #F1F0FE;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .name {
        margin-left: 20px;
        width: 1%;
        flex: 1;
    }
}
